<template>
	<div class="login">
		<div class="loginbox">
			<div class="head">
				Mini Program JDT 2021
			</div>
			<el-input v-model="email" placeholder="Email address"></el-input>
			<el-input v-model="password" type="password" placeholder="Password"></el-input>
			<el-button type="warning" @click="login" size="small">log in</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				email:'',
				password:'',
				redirect:''
			}
		},
		created() {
			if(this.$route.query && this.$route.query.redirect){
				this.redirect = this.$route.query.redirect
				this.$store.commit('logout')
			}else if(this.$store.state.token){
				this.$message({
					message:'You are signed in',
					type:'error'
				})
				this.$router.push({
					path:'/matches'
				})
			}
		},
		methods:{
			login(){
				this.$loading({background:'rgba(0,0,0,0.5)'})
				this.$axios({
					method:'post',
					url:'/login',
					data:{
						account:this.email,
						password:this.password
					}
				}).then(res=>{
					console.log(res.data)
					this.$loading().close()
					if(res.data.code==200){
						this.$store.commit('setToken',res.data.token)
						this.$router.push({
							path:this.redirect ? this.redirect : '/merchants'
						})
					}else{
						this.$message({
							message:res.data.msg,
							type:'error'
						})
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-input__inner{
		color: #666!important;
	}
	.login{
		width: 100vw;
		height: calc(100vh - 81px);
		background-color: #1c1c1e;
		overflow: hidden;
		.loginbox{
			width: 300px;
			margin:0 auto;
			margin-top: calc(50vh - 220px);
			display: flex;
			flex-direction: column;
			height: 360px;
			align-items: center;
			justify-content: space-around;
			text-align: center;
			.head{
				color: #fff;
				font-size: 40px;
				font-weight: bold;
			}
			.el-button{
				margin-top: 30px;
			}
		}
	}
</style>
